<template>
  <div>
    <ClientOnly>
      <Modals/>
    </ClientOnly>

    <!-- navbar -->
    <NavBar v-if="showNavBar"/>

    <main class="main">
      <slot/>
    </main>

    <Footer v-show="showFooter"/>

    <ClientOnly>
      <Snackbar/>
    </ClientOnly>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from '@/stores'
import { useRoute } from 'vue-router'
import { ClientOnly } from 'vite-plugin-vue-ssr'

import { Capacitor } from '@capacitor/core'
import LayoutMixin from '@/mixins/layout.vue'

// Component imports
import Modals from '@/components/Modals/Modals.vue'
import Footer from '@/components/Navigation/Footer/Footer.vue'
import NavBar from '@/components/Navigation/NavBar/NavBar.vue'
import Snackbar from '@/components/Snackbar/Snackbar.vue'

export default {
  name: 'DefaultLayout',

  components: {
    Modals,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer,
    NavBar,
    Snackbar,
    ClientOnly,
  },

  mixins: [LayoutMixin,],

  setup() {
    const route = useRoute()
    const store = useStore()

    const isNative = computed(() => {
      return Capacitor.isNativePlatform()
    })

    const showFooter = computed(() => {
      // hide footer on establishment pages on mobile
      if (route.name.includes('establishment') && store.getters['viewport/isMobile']) return false

      return !(isNative.value || route.name?.includes('checkout'))
    })

    const showNavBar = computed(() => {
      // If we are not on a page, don't show the navbar
      if (!route.name) return false

      // Don't show the navbar on the homepage
      if (route.name.includes('home')) return false

      // Show the navbar everywhere else
      return true
    })

    return {
      showFooter,
      showNavBar,
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
}
</style>
